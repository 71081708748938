import React from "react";
import backgroundHero from "../assets/images/background_stepbystep.png";

const StepByStepSection = () => {
  return (
    <div
      className="relative text-white text-center p-12 overflow-hidden"
      style={{
        backgroundColor: "#4C0070",
        backgroundImage: `url(${backgroundHero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "1000px",
      }}
    >
      {/* Background image with gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-purple-50 via-transparent to-transparent opacity-75"></div>

      <div className="container mx-auto relative text-left">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "55%",
              paddingTop: "5%",
              paddingLeft: "5%",
              height: "773px",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2
              className="font-aeonik text-5xl font-bold mb-0
              bg-gradient-to-br from-purple-500 to-pink-500
              text-transparent bg-clip-text"
              style={{
                fontSize: "60px",
                fontWeight: "700",
                lineHeight: "1",
                margin: "0px",
                padding: "0px",
              }}
            >
              Passo
            </h2>
            <h2
              className="font-aeonik text-5xl font-bold mb-6
              bg-gradient-to-br from-purple-500 to-pink-500
              text-transparent bg-clip-text"
              style={{
                fontSize: "60px",
                fontWeight: "700",
                lineHeight: "1",
                margin: "0px",
                padding: "0px",
              }}
            >
              a passo
            </h2>
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              paddingTop: "5%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                justifyContent: "left",
                gap: "0px",
              }}
            >
              <h2
                className="font-aeonik text-5xl font-bold mb-6
                  bg-gradient-to-br from-purple-500 to-pink-500
                  text-transparent bg-clip-text"
                style={{
                  fontSize: "120px",
                  lineHeight: "130px",
                  fontWeight: "700",
                }}
              >
                1
              </h2>
              <div
                className="font-aeonik mb-8"
                style={{
                  color: "#5A5A5A",
                  fontSize: "40px",
                  paddingLeft: "100px",
                }}
              >
                Cadastre a máquina
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                justifyContent: "left",
                gap: "0px",
              }}
            >
              <h2
                className="font-aeonik text-5xl font-bold mb-6
                  bg-gradient-to-br from-purple-500 to-pink-500
                  text-transparent bg-clip-text"
                style={{
                  fontSize: "120px",
                  lineHeight: "130px",
                  fontWeight: "700",
                }}
              >
                2
              </h2>
              <div
                className="font-aeonik mb-8"
                style={{
                  color: "#5A5A5A",
                  fontSize: "40px",
                  paddingLeft: "100px",
                }}
              >
                Instale o dispositivo
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                justifyContent: "left",
                gap: "0px",
              }}
            >
              <h2
                className="font-aeonik text-5xl font-bold mb-6
                  bg-gradient-to-br from-purple-500 to-pink-500
                  text-transparent bg-clip-text"
                style={{
                  fontSize: "120px",
                  lineHeight: "130px",
                  fontWeight: "700",
                }}
              >
                3
              </h2>
              <div
                className="font-aeonik mb-8"
                style={{
                  color: "#5A5A5A",
                  fontSize: "40px",
                  paddingLeft: "100px",
                }}
              >
                Sincronize com o aplicativo
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                gap: "0px",
              }}
            >
              <h2
                className="font-aeonik text-5xl font-bold mb-6
                  bg-gradient-to-br from-purple-500 to-pink-500
                  text-transparent bg-clip-text"
                style={{
                  fontSize: "120px",
                  lineHeight: "130px",
                  fontWeight: "700",
                }}
              >
                4
              </h2>
              <div
                className="font-aeonik mb-9"
                style={{
                  color: "#5A5A5A",
                  fontSize: "40px",
                  paddingLeft: "100px",
                }}
              >
                Está pronto!
              </div>
            </div>

            <div
              className="font-aeonik mb-9"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                gap: "0px",
                color: "#5A5A5A",
                fontSize: "40px",
              }}
            >
              Basta acessar Eulera para monitoramento!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepByStepSection;
