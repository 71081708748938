import React from "react";
import HeroSection from "./components/HeroSection";
import FeatureSection from "./components/FeatureSection";
import CommunicationSection from "./components/CommunicationSection";
import ESGSection from "./components/ESGSection";
import StepByStepSection from "./components/StepByStepSection";
import VisibilitySection from "./components/VisibilitySection";
import Footer from "./components/Footer";

const App = () => (
  <div>
    <HeroSection />
    <FeatureSection />
    <VisibilitySection />
    <CommunicationSection />
    <ESGSection />
    <StepByStepSection />
    <Footer />
  </div>
);

export default App;
