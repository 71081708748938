import React from "react";
import backgroundHero from "../assets/images/background_feature.png";

const FeatureSection = () => {
  return (
    <div
      className="relative text-white text-center p-12 overflow-hidden"
      style={{
        backgroundColor: "#4C0070",
        backgroundImage: `url(${backgroundHero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "1000px",
        paddingLeft: "10%",
        alignContent: "left",
      }}
    >
      {/* Overlay for text readability */}
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>

      <div className="container mx-auto relative text-left">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2
              className="font-aeonik text-5xl font-bold mb-6"
              style={{
                color: "#FC77F2",
                fontSize: "64px",
                lineHeight: "73px",
                fontWeight: "700",
              }}
            >
              Inteligência Artificial
            </h2>
            <h2
              className="font-aeonik text-5xl mb-6"
              style={{
                fontSize: "64px",
              }}
            >
              chega a Indústria 4.0
            </h2>
            <p className="font-aeonik mb-6" style={{ fontSize: "32px" }}>
              Com mapeamento avançado do seu maquinário,
              <span style={{ fontWeight: "bold" }}> Eulera </span>
              otimiza a sua produção
            </p>
            <div className="h-10" />
            <h3
              className="font-aeonik text-3xl font-bold mb-1"
              style={{ color: "#BB51E0" }}
            >
              Economia de Energia
            </h3>
            <p className="font-aeonik text-2xl mb-8">
              Receba sugestões em tempo real de ajustes em suas máquinas para
              reduzir seu consumo de energia mantendo a qualidade do produto
            </p>
            <h3
              className="font-aeonik text-3xl font-bold mb-1"
              style={{ color: "#BB51E0" }}
            >
              Produtividade
            </h3>
            <p className="font-aeonik text-2xl mb-8">
              Analisamos e sugerimos as melhores configurações do maquinário
              para garantir o máximo de produtividade dentro do contexto da
              fábrica
            </p>
            <h3
              className="font-aeonik text-3xl font-bold mb-1"
              style={{ color: "#BB51E0" }}
            >
              Planejamento Utilizando
            </h3>
            <p className="font-aeonik text-2xl mb-8">
              Inteligência Artificial, Eulera consegue sugerir manutenções
              preventivas do maquinário
            </p>
          </div>
          {/* The second column can be reserved for an image or additional content */}
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
