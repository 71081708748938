import React from "react";
import logo from "../assets/images/general_logo.png"; // Adjust if necessary
import backgroundHero from "../assets/images/background_hero.png"; // Import the background image
import Navbar from "./NavBar";

const HeroSection = () => {
  const gradientTextStyle = {
    background: "linear-gradient(to right, #FC77F2 100%, #BB51E0 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline",
    fontSize: "50px",
    lineHeight: "60px",
    fontWeight: "700",
    letterSpacing: "10px",
  };

  return (
    <div
      className="relative text-white text-center p-12 overflow-hidden"
      style={{
        backgroundColor: "#4C0070",
        backgroundImage: `url(${backgroundHero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "1000px",
      }}
    >
      {/* Background image with gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900 via-transparent to-transparent opacity-75"></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center content-center justify-center h-full">
        <Navbar />
        <div className="h-20" />
        {/* Logo */}
        <img src={logo} alt="Eulera Logo" className="h-190 mb-4" />{" "}
        <div className="h-5 mb-4"></div>
        {/* Adjust size as needed */}
        {/* Hero text */}
        <div
          className="bg-[#190A1C] rounded-lg px-8 py-2"
          style={{ borderRadius: "29px" }}
        >
          {" "}
          {/* This div creates the rectangle */}
          <h1
            className="font-aeonik text-4xl font-bold leading-none"
            style={gradientTextStyle}
          >
            EFICIÊNCIA E ECONOMIA
          </h1>
        </div>
        <h2 className="text-3xl mt-2" style={gradientTextStyle}>
          PARA SUA FÁBRICA!
        </h2>
        <p
          className="mt-4 mx-auto leading-relaxed max-w-6xl"
          style={{ fontSize: "32px" }}
        >
          Eulera é uma plataforma de Inteligência Artificial criada para apoiar
          a sua fábrica. Colocando mais visibilidade, produtividade e economia
          na palma da sua mão, Eulera acelera o seu negócio.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
