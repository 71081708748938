/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../assets/images/logo.png";

const Navbar = () => {
  return (
    <nav
      className="bg-transparent p-4 text-white flex justify-between items-center w-full h-20"
      style={{
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <div className="flex items-center">
        {/* Logo */}
        <a href="/" className="text-3xl font-bold">
          <img src={logo} alt="Logo" className="h-43" />
        </a>
      </div>
      <div className="flex space-x-4">
        {/* Navigation Links */}

        <a
          href="#"
          className="font-aeonik p-2 rounded transition duration-100 ease-in-out text-lg hover:text-purple-600"
          style={{ fontSize: "20px", fontWeight: "700" }}
        >
          Home
        </a>
        <a
          href="https://wa.me/5511985856426"
          target="_blank"
          rel="noopener noreferrer"
          className="font-aeonik p-2 rounded transition duration-100 ease-in-out text-lg hover:text-purple-600"
          style={{ fontSize: "20px", fontWeight: "700" }}
        >
          Contato
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
