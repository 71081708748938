import React from "react";
import backgroundHero from "../assets/images/background_communication.png";
import cellPhonePhadeImage from "../assets/images/image_communication_1.png";

const CommunicationSection = () => {
  return (
    <div
      className="relative text-white text-center p-12 overflow-hidden"
      style={{
        backgroundColor: "#4C0070",
        backgroundImage: `linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.3)), url(${backgroundHero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "1000px",
      }}
    >
      <div className="container mx-auto relative text-left">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <div style={{ height: "200px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "0px",
                position: "relative",
                marginRight: "-60px",
              }}
            >
              <h2
                className="font-aeonik font-bold
                bg-gradient-to-r from-zinc-50 to-zinc-200
                text-transparent bg-clip-text"
                style={{
                  lineHeight: "1",
                  fontSize: "120px",
                  fontWeight: "700",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Falamos
              </h2>
              <h2
                className="font-aeonik font-bold
                bg-gradient-to-r from-zinc-50 to-zinc-300
                text-transparent bg-clip-text"
                style={{
                  lineHeight: "1",
                  padding: "0px",
                  fontSize: "120px",
                  fontWeight: "700",
                  margin: "-40px 0px 0px 0px",
                }}
              >
                com você
              </h2>
            </div>

            <div style={{ height: "200px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "left",
                width: "100%",
                position: "relative",
              }}
            >
              <p
                className="font-aeonik mb-8"
                style={{
                  maxWidth: "600px",
                  fontSize: "40px",
                }}
              >
                Em caso de qualquer dúvida, Eulera conta com conexão ao ChatGPT
                para acessar seus dados de maquinário e lhe apoiar com
                informações de forma direta e simples.
              </p>
              <p
                className="font-aeonik text-left"
                style={{ fontSize: "40px", fontWeight: "bold" }}
              >
                Basta perguntar!
              </p>
            </div>
          </div>

          <div
            style={{
              width: "40%",
              height: "773px",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ height: "100px" }} />
            <img
              src={cellPhonePhadeImage}
              alt="cellphonephadeimage"
              style={{ height: "773px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationSection;
