import React from "react";
import backgroundHero from "../assets/images/background_esg.png";

const ESGSection = () => {
  return (
    <div
      className="relative text-white text-center p-12 overflow-hidden"
      style={{
        backgroundColor: "#4C0070",
        backgroundImage: `url(${backgroundHero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "1000px",
      }}
    >
      {/* Overlay for text readability */}
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>

      <div className="container mx-auto relative text-left">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div
            style={{
              paddingTop: "20%",
              paddingLeft: "25%",
            }}
          >
            <h2
              className="font-aeonik text-5xl font-bold mb-6
              bg-gradient-to-r from-purple-500 to-pink-500
              text-transparent bg-clip-text"
              style={{
                fontSize: "120px",
                fontWeight: "700",
              }}
            >
              ESG
            </h2>

            <p className="font-aeonik mb-6" style={{ fontSize: "40px" }}>
              Apoiamos sua fábrica no consumo energético consciente e otimização
              de uso de matéria prima, contribuindo para redução de sua pegada
              de carbono, preservação do meio ambiente e governança.
            </p>
          </div>
          {/* The second column can be reserved for an image or additional content */}
        </div>
      </div>
    </div>
  );
};

export default ESGSection;
