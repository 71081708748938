import React from "react";
import generalLogo from "../assets/images/general_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div
      className="relative text-white text-center p-12 overflow-hidden"
      style={{
        backgroundColor: "#0F0F0F",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "300px",
        maxHeight: "300px",
      }}
    >
      <div className="container mx-auto relative text-left">
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%", paddingTop: "30px" }}>
            <img
              src={generalLogo}
              alt="footerlogo"
              style={{ height: "129px" }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              paddingRight: "10%",
              paddingTop: "30px",
            }}
          >
            <div style={{ textAlign: "right" }}>
              {" "}
              {/* Align the button to the right */}
              <a
                href="mailto:contato@eulera.ai"
                style={{ textDecoration: "none" }}
              >
                <button
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    border: "2px solid #FC77F2",
                    color: "#FC77F2",
                    padding: "15px 32px",
                    fontSize: "30px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "12px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    color="#FC77F2"
                    style={{ marginRight: "10px" }}
                  />{" "}
                  {/* Envelope icon */}
                  Contato via email
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
