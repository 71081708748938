import React from "react";
import backgroundHero from "../assets/images/background_visibility.png";
import cellPhoneImage from "../assets/images/image_visibility_1.png";

const VisibilitySection = () => {
  return (
    <div
      className="relative text-white text-center p-12 overflow-hidden"
      style={{
        backgroundColor: "#4C0070",
        backgroundImage: `url(${backgroundHero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "1000px",
        maxHeight: "1000px",
      }}
    >
      <div className="container mx-auto relative text-left">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "30%",
              height: "773px",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={cellPhoneImage}
              alt="cellphoneimage"
              style={{ height: "773px" }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <h2
              className="font-aeonik text-5xl font-bold mb-6
              bg-gradient-to-b from-white to-zinc-300
              text-transparent bg-clip-text"
              style={{
                fontSize: "120px",
                lineHeight: "130px",
                fontWeight: "700",
              }}
            >
              Visibilidade
            </h2>
            <p className="font-aeonik mb-8" style={{ fontSize: "40px" }}>
              É importante que os gestores da fábrica tenham
              <span style={{ fontWeight: "bold" }}> visibilidade </span>
              dos dados coletados.
            </p>
            <p className="font-aeonik" style={{ fontSize: "40px" }}>
              Eulera é desenvolvida para oferecer um acompanhamento da
              performance de suas máquinas em tempo real e na palma da sua mão.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisibilitySection;
